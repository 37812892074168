





/**
 * The Version Control protocol
 */
import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import Company from '@/models/Company';
import ArchiveComponent from '@/components/shared/Archive.component.vue';

const CompanyStore = namespace('company');


@Component({
  components: {ArchiveComponent},
})
/**
 * Component to visualize the different Versions of a Company
 */
export default class CompanyArchiveComponent extends Vue {

  @CompanyStore.Action('loadCompanyOrigin')
  private loadCompanyOrigin!: (companyId: string) => Promise<Company[]>;
  @CompanyStore.Getter('activeCompany')
  private _company!: Company;


  private companyVersions: Company[] = [];
  private loaded: boolean = false;


  public async mounted() {
    this.companyVersions = await this.loadCompanyOrigin(this.$route.params.companyId);
    this.companyVersions.push(this._company);
    this.loaded = true;
  }
}
