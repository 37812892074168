



































































/**
 * Represents the master data of a company
 */
import { Component, Prop, Vue } from 'vue-property-decorator';
import Company from '@/models/Company';
import SideCardComponent from '@/components/shared/SideCard/SideCard.component.vue';
import CompanyManageComponent from '@/components/company/CompanyManage.component.vue';
import BaseDataCellComponent from '@/components/shared/BaseDataCell.component.vue';

@Component({
  components: {BaseDataCellComponent, SideCardComponent, CompanyManageComponent},
})
export default class CompanyMasterDataComponent extends Vue {

    @Prop({default: undefined})
    public readonly company: Company | undefined;

}
